import axios from "axios";
import history from "./history.js";
import userInfoStore from "../store/UserInfo.Store";

const http = axios.create({
    baseURL: window.location.protocol + "//lifebeating.ie/api/"
    // baseURL: 'http://localhost:8080/',
    // timeout: 10000
});

http.interceptors.request.use((request) => {
    const token = window.localStorage.getItem("token");
    if (token) {
        request.headers.token = token;
    }
    return request;
});

http.interceptors.response.use((response) => {

    if (window.location.pathname !== "/login" && response.data?.code === 401) {
        window.localStorage.removeItem("token");
        userInfoStore.setUserInfo({activationStatus: null, displayName: null, cartAmount: null});
        if (response.data?.msg.endsWith("expired")) {
            window.location.reload();
        } else {
            history.push("/login", {from: window.location.pathname});
        }
    }

    return response;
});

export default http;
